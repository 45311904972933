<template>
  <transition name="fade">
    <div class="toast" v-if="visible">{{message}}</div>
  </transition>
</template>

<script>
import { onMounted, ref } from 'vue'

export default {
  name: 'toast',
  props: {
    message: {
      type: String,
      default: ''
    }
  },
  setup() {
    const visible = ref(false)
    onMounted(() => {
      visible.value = true
    })

    return {
      visible
    }
  }
}
</script>

<style lang="less" scoped>
.toast {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  display: inline-block;
  max-width: 180px;
  padding: 12px 20px;
  background-color: rgba(0, 0, 0, .8);
  text-align: center;
  color: white;
  font-size: 16px;
  border-radius: 4px;
}
</style>
