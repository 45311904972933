import axios from 'axios'
import router from '@/router'
import store from '@/store'
import Toast from '@/components/toast/toast'

const server = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? process.env.VUE_APP_BASE_API : location.origin + process.env.VUE_APP_BASE_API,
  timeout: 1000 * 15
})

// 请求拦截器
server.interceptors.request.use(config => {
  if(store.getters.profile.token) {
    config.headers['token'] = store.getters.profile.token
  }

  return config
}, error => {
  return Promise.reject(error)
})

// 响应拦截器
server.interceptors.response.use(response => {
  const { code, msg } = response.data

  if(code === 0) {
    Toast({ message: msg })
    return Promise.reject(msg)
  }

  return response.data
}, error => {
  if(error.response && error.response.status === 401) {
    store.commit('user/SET_PROFILE', {})
    const fullPath = encodeURIComponent(router.currentRoute.value.fullPath)
    router.replace(`/login?redirect_url=${fullPath}`)
  }
  if(error.response.data) {
    Toast({ message: error.response.data.msg || '系统维护中...' })
  }
  return Promise.reject(error.message)
})

export default server
