import { render, createVNode } from 'vue'
import Toast from './index'

let div = document.createElement('div')
div.setAttribute('class', 'toast-container')
document.body.appendChild(div)

let timer = null

export default function({ message, duration = 3000 }) {
  const vnode = createVNode(Toast, { message })
  render(vnode, div)

  clearTimeout(timer)
  timer = setTimeout(() => {
    render(null, div)
  }, duration)
}
